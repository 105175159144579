//Theme Overwrite------------------------------
.bg-primary {
  background: #7163ba !important;
}

.text-primary {
  color: #7163ba !important;
}

.btn-primary {
  background: #7163ba !important;
  border-color: #7163ba !important;
}

.btn-outline-primary {
  border-color: #7163ba !important;
  color: #7163ba !important;
}

.btn-outline-primary:hover {
  background: #7163ba !important;
  color: white !important;
}

.btn-outline-primary.active {
  background: #7163ba !important;
  color: white !important;
}

// SIDEBAR AND MARGIN ADJUSTMENT-----------------------------
.main-content-hidden {
  margin-left: 90px !important;
  margin-right: 0px !important;
}

.main-content-show {
  margin-left: 200px !important;
  margin-right: 0px !important;
}

@media only screen and (max-width: 767px) {
  .main-content-hidden {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .main-content-show {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

// borderRadius--------------
.borderRadius {
  border-radius: 50px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//Projects ImageIcon------------------------------
.icon-card {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background: #7163ba;
  border-radius: 25px;
  padding: 15px 20px;
  box-shadow: rgba(0, 0, 0, 0.473) 0px 4px 8px;
  width: 90px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
  }
}


// Switch button---------------------------------------
.switch {
  display: flex;
  align-items: center;
  margin: 0;
  --button-width: 4em;
  --button-height: 1.7em;
  --toggle-diameter: 1.4em;
  --button-toggle-offset: calc((var(--button-height) - var(--toggle-diameter)) / 2);
  --toggle-shadow-offset: 10px;
  --toggle-wider: 3em;
  --color-grey: #cccccc;
  --color-green: #7163ba;
}

.slider {
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: var(--color-grey);
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  transition: 0.3s all ease-in-out;
}

.slider::after {
  content: "";
  display: inline-block;
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  background-color: white;
  border-radius: calc(var(--toggle-diameter) / 2);
  position: absolute;
  top: var(--button-toggle-offset);
  transform: translateX(var(--button-toggle-offset));
  box-shadow: var(--toggle-shadow-offset) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.switch input[type="checkbox"]:checked+.slider {
  background-color: var(--color-green);
}

.switch input[type="checkbox"]:checked+.slider::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)));
  box-shadow: calc(var(--toggle-shadow-offset) * -1) 0 calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch input[type="checkbox"] {
  display: none;
}

.switch input[type="checkbox"]:active+.slider::after {
  width: var(--toggle-wider);
}

.switch input[type="checkbox"]:checked:active+.slider::after {
  transform: translateX(calc(var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)));
}

// FlipCard--------------------------------------
.flip-card {
  background: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.flip-card-top {
  display: flex;
  justify-content: space-between;
  background: #7163ba;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid grey;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 180px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
}

.flip-card-inner-toggle {
  position: relative;
  width: 100%;
  height: 180px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.flip-card-back {
  transform: rotateY(180deg);
}

.pac-container {
  z-index: 9999 !important;
}

.progressbar {
  width: 100%;
  height: 4px;
}

.overflowX {
  overflow-x: scroll;
}

.gap-10 {
  gap: 10px
}