//
// Avatar
//

// General styles

.avatar {
	color: $white;
	// background-color: $gray-500;
	background-color: white !important;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	border-radius: 50%;
	height: 48px;
	width: 48px;

    img {
    	width: 100%;
    	border-radius: 50%;
    }

    + .avatar-content {
    	display: inline-block;
    	margin-left: .75rem;
    }
}


// Avatar size variations

.avatar-lg {
	width: 58px;
	height: 58px;
	font-size: $font-size-sm;
}

.avatar-sm {
	width: 30px;
	height: 30px;
	font-size: $font-size-sm;
}
